import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IUserSections } from '../../types/user.d';

interface IUserState {
  currentUser: IUserSections | null;
}

const initialState: IUserState = {
  currentUser: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveCurrentUser: (state, action: PayloadAction<IUserSections | null>) => {
      state.currentUser = action.payload;
    },
  },
});

export const { saveCurrentUser } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;

export default userSlice.reducer;
