import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IClientPowerbi } from '../../types/client.d';

interface IClientState {
  currentClient: IClientPowerbi | null;
}

const initialState: IClientState = {
  currentClient: null,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    saveCurrentClient: (state, action: PayloadAction<IClientPowerbi>) => {
      state.currentClient = action.payload;
    },
  },
});

export const { saveCurrentClient } = clientSlice.actions;

export const selectCurrentClient = (state: RootState) => state.client.currentClient;

export default clientSlice.reducer;
