import IUser, { IUserSections, UserRole } from '../../types/user.d';
import apiSlice from '../api/api.slice';
import Tags from '../tags';

interface IUpdateUser {
  id: string;
  role: string;
}

const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<IUser[], UserRole | void>({
      query: (role: UserRole) => ({
        url: role ? `/users?role=${role}` : '/users',
        method: 'GET',
      }),
      providesTags: [Tags.USERS],
    }),
    getUser: builder.query<IUserSections, void>({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: [Tags.USER],
    }),
    updateUser: builder.mutation<IUser, IUpdateUser>({
      query: (args: IUpdateUser) => ({
        url: `/users/${args.id}`,
        method: 'PUT',
        body: {
          role: args.role,
        },
      }),
      invalidatesTags: [Tags.USER, Tags.CLIENT, Tags.CLIENT_USERS],
    }),
    deleteUser: builder.mutation<IUser, string>({
      query: (userId: string) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.USERS],
    }),
    syncGlobalAdmins: builder.mutation<void, void>({
      query: () => ({
        url: '/users/sync/global-admins',
        method: 'GET',
      }),
      invalidatesTags: [Tags.USERS],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useSyncGlobalAdminsMutation,
} = userApiSlice;

export default userApiSlice;
