import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { FileEarmarkLock2Fill, GearFill, PersonFillGear } from 'react-bootstrap-icons';
import {
  selectCurrentSettingsTab,
  setCurrentSettingsTab,
} from '../../features/settings/settings.slice';
import { UserRole } from '../../types/user.d';
import { isSupport } from '../../utils/authorization.utils';
import { selectCurrentUser } from '../../features/user/user.slice';
import { useAppDispatch, useAppSelector } from '../../features/hooks';
import UserSettings from '../../components/UserSettings/UserSettings';
import { selectIsGuardianSession } from '../../features/auth/auth.slice';
import { selectCurrentClient } from '../../features/client/client.slice';
import GroupSettings from '../../components/GroupSettings/GroupSettings';
import ReportSettings from '../../components/ReportSettings/ReportSettings';
import { useGetClientUsersQuery } from '../../features/client/clientApi.slice';

/**
 * TODO: Find a better way to reload than refreshing the page.
 * TODO: When a report or user is changed, the order in the object is not the same, therefore the _.isEqual function is false.
 */

function Settings(): JSX.Element {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const client = useAppSelector(selectCurrentClient);
  const { data: clientUsers } = client?.id
    ? useGetClientUsersQuery({ clientId: client.id })
    : { data: null };
  const currentTab = useAppSelector(selectCurrentSettingsTab);
  const isGuardianSession = useAppSelector(selectIsGuardianSession);
  const readOnly = !!currentUser && isSupport(currentUser.role, isGuardianSession);

  return (
    <div id="settingsPage">
      {currentUser && client && (
        <Container>
          <Tabs defaultActiveKey={currentTab}>
            <Tab
              eventKey="groups"
              title={
                <div className="tab-title">
                  <FileEarmarkLock2Fill size={18} />
                  <div>Report access</div>
                </div>
              }
              onClick={() => dispatch(setCurrentSettingsTab('groups'))}
            >
              <GroupSettings clientId={client.id} readOnly={readOnly} />
            </Tab>
            <Tab
              eventKey="users"
              title={
                <div className="tab-title">
                  <PersonFillGear size={20} />
                  <div>Users settings</div>
                </div>
              }
              onClick={() => dispatch(setCurrentSettingsTab('users'))}
            >
              <UserSettings
                clientUsers={clientUsers?.filter((u) => u.role !== UserRole.GLOBAL_ADMIN)}
                readOnly={readOnly}
              />
            </Tab>
            <Tab
              eventKey="reports"
              title={
                <div className="tab-title">
                  <GearFill size={18} />
                  <div>Report settings</div>
                </div>
              }
              onClick={() => dispatch(setCurrentSettingsTab('reports'))}
            >
              <ReportSettings clientId={client.id} />
            </Tab>
          </Tabs>
        </Container>
      )}
    </div>
  );
}

export default Settings;
