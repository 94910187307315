import { IClientPowerbi } from './client.d';
import { ISectionReports } from './section.d';

// TODO: review the data shared between service and client
interface IUser {
  id: string;
  email: string;
  role: UserRole;
  version: number;
  clientId: string;
  createdAt: string;
  deletedAt: string | null;
  updatedAt: string | null;
}

export interface IUserClientPowerbi extends IUser {
  client: IClientPowerbi;
}

export interface IUserSections extends IUserClientPowerbi {
  sections: ISectionReports[];
}

export interface ICreateUser {
  email: string;
  role: UserRole;
}

export enum UserRole {
  GLOBAL_ADMIN = 'global_admin',
  ADMIN = 'admin',
  SUPPORT = 'support',
  VIEWER = 'viewer',
}

export enum SessionType {
  STAFF = 'Staff',
  GUARDIAN = 'Guardian',
}

export default IUser;
