import { configureStore } from '@reduxjs/toolkit';
import apiSlice from './api/api.slice';
import userReducer from './user/user.slice';
import authReducer from './auth/auth.slice';
import layoutReducer from './layout/layout.slice';
import reportReducer from './report/report.slice';
import clientReducer from './client/client.slice';
import settingsSlice from './settings/settings.slice';
import msGraphTokenReducer from './msGraphToken/msGraphToken.slice';
import edubiServiceTokenReducer from './edubiServiceToken/edubiServiceToken.slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    client: clientReducer,
    report: reportReducer,
    layout: layoutReducer,
    settings: settingsSlice,
    msGraphToken: msGraphTokenReducer,
    edubiServiceToken: edubiServiceTokenReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
